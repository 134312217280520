import PropTypes from "prop-types";

import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { STATE_KEY as USER_STATE_KEY } from "../User";
import styled from "styled-components";
import { useSession } from "../../hooks";

import OALogo from "../../core/assets/Orien_logo_KO_2ndLOGOS_ORIENAVATAR.svg";
import UserAvatarIcon from "./assets/UserAvatar.svg";

import "./styles/profile.scss";

const OALogoWrapper = styled.div`
  img {
    height: 80px;
  }
`;

const AvatarWrapper = styled.div`
  width: 45px;
`;

const UserDescriptionLogoutWrapper = styled.div`
  font-size: 1.2rem;
  color: #ffffff;
`;

const FullnameWrapper = styled.div``;

const CompanyWrapper = styled.div`
  font-size: 1.1rem;
  color: #d0d0d0;
`;

const LogoutWrapper = styled.div`
  margin-top: 4px;
  font-size: 1.3rem;
  color: #68b9e7;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Profile = () => {
  const user = useSelector(state => state[USER_STATE_KEY]);
  const { fullName, title, company, unreadMessages } = user;
  const { endSession } = useSession();

  return (
    <nav className="Profile">
      <OALogoWrapper>
        <Link to="/">
          <img src={OALogo} alt="ORIEN AVATAR" />
        </Link>
      </OALogoWrapper>
      <div id="mobile-logout">
        <Link to="/account/logout">
          <span className="user-logout">Logout</span>
        </Link>
      </div>
      <div id="user">
        <div className="user-img-wrapper">
          <Link to="/account">
            <AvatarWrapper>
              <img src={UserAvatarIcon} alt="User Profile icon" title="Click here to access your profile." />
            </AvatarWrapper>
            {unreadMessages > 0 && (
              <div id="user-notifications" className="shape-circle">
                {unreadMessages}
              </div>
            )}
          </Link>
        </div>
        <UserDescriptionLogoutWrapper>
          <FullnameWrapper>{fullName}</FullnameWrapper>
          <CompanyWrapper>{company}</CompanyWrapper>
          <LogoutWrapper onClick={endSession}>Logout</LogoutWrapper>
        </UserDescriptionLogoutWrapper>
      </div>
    </nav>
  );
};
