import React from "react";
import { Menu } from "../Menu";
import { Profile } from "../Profile";

//Styles
import "./styles/Navigation.scss";

export const Navigation = () => {
  return (
    <div className="Navigation noprint">
      <Profile />
      <Menu />
    </div>
  );
};
