import React, { useEffect, useMemo, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Chevron, MolecularDataByDiseaseGrid, MolecularDataByDiseasePie, STATE_KEY as MOLECULAR_STATE_KEY } from "../../Molecular";
import { STATE_KEY as USER_STATE_KEY } from "../../User";
import ContentLoader from "react-content-loader";
import * as actions from "../actions";
import axios from "axios";
import * as Utils from "../../../data/utilities";
import { AbilityContext, subjects, Can } from "../../../ability";
import styled from "styled-components";

export const Molecular = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state[USER_STATE_KEY]);
  const molecularSelector = useSelector(state => state[MOLECULAR_STATE_KEY]);
  const summaryData = molecularSelector.summary;
  const totalsData = molecularSelector.totals;
  const diseasesData = molecularSelector.diseases;
  const ability = useContext(AbilityContext);
  const isSpecimenToggleSelected = molecularSelector.toggleValue == "Specimen";
  const isPatientToggleSelected = molecularSelector.toggleValue == "Patient";

  // Prepare data for Chevrons -------------------------
  const molecularChevronPatientData = useMemo(() => {
    if (ability.can("view", subjects.ROLE_ORIEN)) {
      return [
        { title: "Manifest Created", value: summaryData.PatientManifestCreated },
        { title: "Sample Received", value: summaryData.PatientReceived },
        { title: "Extracted/Sent", value: summaryData.PatientExtractedSent },
        { title: "Sequenced", value: summaryData.PatientSequenced },
        { title: "Molecular File Received", value: summaryData.PatientMolecularFileReceived },
        { title: "Passed QC", value: summaryData.PatientPassedQC },
        { title: "Validated", value: summaryData.PatientValidated }
      ];
    } else {
      return [
        { title: "Received", value: summaryData.PatientReceived },
        { title: "Sequenced", value: summaryData.PatientSequenced },
        { title: "Passed QC", value: summaryData.PatientPassedQC }
      ];
    }
  }, [summaryData]);
  const molecularChevronSpecimenData = useMemo(() => {
    if (ability.can("view", subjects.ROLE_ORIEN)) {
      return [
        { title: "Manifest Created", value: summaryData.PatientManifestCreated },
        { title: "Sample Received", value: summaryData.SampleReceived },
        { title: "Extracted/Sent", value: summaryData.SampleExtractedSent },
        { title: "Sequenced", value: summaryData.SampleSequenced },
        { title: "Molecular File Received", value: summaryData.PatientMolecularFileReceived },
        { title: "Passed QC", value: summaryData.SamplePassedQC },
        { title: "Validated", value: summaryData.SampleValidated }
      ];
    } else {
      return [
        { title: "Received", value: summaryData.SampleReceived },
        { title: "Sequenced", value: summaryData.SampleSequenced },
        { title: "Passed QC", value: summaryData.SamplePassedQC }
      ];
    }
  }, [summaryData]);

  // Prepare data for charts ----------------------------
  const molecularDiseaseColumns = useMemo(
    () => [
      { title: "Cancer Group", key: "CancerGroup", dataKey: "CancerGroup", width: 120, resizable: true, flexGrow: 1 },
      { title: "Disease Type", key: "DiseaseType", dataKey: "DiseaseType", width: 250, resizable: true , flexGrow: 2 },
      { title: "WESTumor", key: "WESTumor", dataKey: "WESTumor", width: 100, resizable: true , flexGrow: 1 },
      { title: "RNA", key: "RNA", dataKey: "RNA", width: 100, resizable: true , flexGrow: 1 },
      { title: "WES Germline", key: "WESGermline", dataKey: "WESGermline", width: 150, resizable: true , flexGrow: 1 }
    ],
    []
  );
  const molecularDiseaseData = useMemo(() => diseasesData, [diseasesData]);

  const molecularDataByDiseasePieData = useMemo(
    () =>
      totalsData.Diseases.map(d => {
        return {
          id: d.DiseaseType,
          label: d.DiseaseType,
          value: d.total,
          percent: d.percent
        };
      }),
    [totalsData]
  );

  // Fetch all the data ---------------------------------
  const axiosInstance = axios.create({
    baseURL: `https://${__GENERICAPI__}/orienavatar/${user.userType}/${user.company}/molecular`,
    headers: {
      Authorization: user.apiKey
    }
  });

  const fetchSummaryDataHelper = () => {
    return new Promise((resolve, reject) => {
      //console.log("Promise for fetchSummaryDataHelper");
      Utils.fetchSummaryData(axiosInstance, [], resolve, reject);
    });
  };

  const fetchDetailDataHelper = (dataType, maxPageIndex, startingPageIndex, initialData) => {
    return new Promise((resolve, reject) => {
      //console.log("fetchDetailDataHelper", dataType, maxPageIndex, startingPageIndex);
      Utils.fetchPaginatedDetailData({
        axiosInstance,
        dataType,
        initialData,
        maxPageIndex,
        currentPageIndex: startingPageIndex,
        resolve,
        reject
      });
    });
  };

  const handleRadioToggle = e => {
    //console.log("inside of handleRadioToggle", e.target.value);
    dispatch(actions.setToggleValue(e.target.value));
  };

  useEffect(() => {
    // if the data has already been loaded then exit out of this effect.
    const { summary, disease } = molecularSelector.loaded;
    if (summary && disease) {
      return; // exit out of this effect
    }

    const startingPageIndex = 0;

    // fetch summary information
    dispatch(actions.setDataTypeLoading("summary", true));
    dispatch(actions.setDataTypeLoaded("summary", false));
    fetchSummaryDataHelper().then(response => {
      const { summary, totals, patientpagecount: patientPageCount = 0, diseasepagecount: diseasePageCount = 0, releasespagecount: releasesPageCount = 0 } = response;
      dispatch(actions.setSummaryData(summary));
      dispatch(actions.setDataTypeLoading("summary", false));
      dispatch(actions.setDataTypeLoaded("summary", true));

      dispatch(actions.setTotalsData(totals));
      dispatch(actions.setDataTypeLoading("totals", false));
      dispatch(actions.setDataTypeLoaded("totals", true));

      // fetch Disease Details
      dispatch(actions.setDataTypeLoading("disease", true));
      dispatch(actions.setDataTypeLoaded("disease", false));
      fetchDetailDataHelper(Utils.DATATYPE.DISEASE, diseasePageCount - 1, startingPageIndex, []).then(response => {
        //console.log(`${Utils.DATATYPE.DISEASE} - ${response.length} records`);
        dispatch(actions.setDiseaseData(response));
        dispatch(actions.setDataTypeLoading("disease", false));
        dispatch(actions.setDataTypeLoaded("disease", true));
      });
    });
  }, []); // only run this effect once

  return (
    <>
      {!molecularSelector.loaded.summary ? (
        <SkeletonContentChevron />
      ) : (
        <>
          {isSpecimenToggleSelected && <Chevron data={molecularChevronSpecimenData} />}
          {isPatientToggleSelected && <Chevron data={molecularChevronPatientData} />}
          <ToggleWrapper>
            <ToggleInput type="radio" id="specimen-toggle" name="Specimen" value="Specimen" checked={isSpecimenToggleSelected} onChange={handleRadioToggle} />
            <ToggleLabel htmlFor="specimen-toggle" selected={isSpecimenToggleSelected}>
              Specimen
            </ToggleLabel>
            <ToggleInput type="radio" id="patient-toggle" name="Patient" value="Patient" checked={isPatientToggleSelected} onChange={handleRadioToggle} />
            <ToggleLabel htmlFor="patient-toggle" selected={isPatientToggleSelected}>
              Patient
            </ToggleLabel>
          </ToggleWrapper>
        </>
      )}

      <ChartingGrid>
        {!molecularSelector.loaded.disease ? (
          <ChartWrapper>
            <SkeletonContentTable />
          </ChartWrapper>
        ) : (
          <ChartWrapper>
            <h3>Molecular Specimen Data By Disease</h3>
            <TableWrapper>
              <MolecularDataByDiseaseGrid columns={molecularDiseaseColumns} data={molecularDiseaseData} />
            </TableWrapper>
          </ChartWrapper>
        )}

        {!molecularSelector.loaded.totals ? (
          <ChartWrapper>
            <SkeletonContentPieChart />
          </ChartWrapper>
        ) : (
          <ChartWrapper>
            <h3>Molecular Specimen Data By Disease</h3>
            <MolecularDataByDiseasePie data={molecularDataByDiseasePieData} />
          </ChartWrapper>
        )}
      </ChartingGrid>
    </>
  );
};

const SkeletonContentChevron = () => {
  return (
    <ContentLoader viewBox="0 0 2000, 350">
      <rect x="0" y="0" rx="0" ry="0" width="400" height="30" />
      <rect x="0" y="60" rx="5" ry="5" width="200" height="100" />
      <rect x="210" y="60" rx="5" ry="5" width="200" height="100" />
      <rect x="420" y="60" rx="5" ry="5" width="200" height="100" />
      <rect x="0" y="180" rx="5" ry="5" width="250" height="50" />
      <rect x="280" y="180" rx="5" ry="5" width="250" height="50" />
    </ContentLoader>
  );
};

const SkeletonContentPieChart = () => {
  return (
    <ContentLoader height={400} viewBox="0 0 1000, 600">
      <rect x="0" y="0" rx="0" ry="0" width="400" height="30" />
      <circle cx="300" cy="300" r="230" />
    </ContentLoader>
  );
};

const SkeletonContentTable = () => {
  return (
    <ContentLoader viewBox="0 0 1500 400">
      <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
      <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
      <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
      <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="203" r="12" />
      <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="265" r="12" />
      <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="323" r="12" />
      <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="386" r="12" />
      <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
      <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />
      <circle cx="37" cy="97" r="11" />
      <rect x="26" y="23" rx="5" ry="5" width="400" height="30" />
      <circle cx="1316" cy="88" r="11" />
      <rect x="1337" y="94" rx="0" ry="0" width="134" height="3" />
      <circle cx="77" cy="96" r="11" />
    </ContentLoader>
  );
};

const ToggleWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  border-radius: 3px;
  height: 32px;
  width: 280px;
  background-color: #ffffff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px hsla(0, 0%, 100%, 0.1);
  padding: 2px;
`;

const ToggleInput = styled.input`
  display: none;
`;

const ToggleLabel = styled.label`
  display: inline-block;
  position: relative;
  width: 140px;
  color: ${({ selected }) => (selected ? "#ffffff" : "#1a89c2")};
  background-color: ${({ selected }) => (selected ? "#1a89c2" : "#ffffff")};
  text-align: center;
  cursor: pointer;
  z-index: 2;
  line-height: 32px;
  height: 32px;
  margin-bottom: 0;
  font-size: 1.4rem;
  transition: 0.15s ease-out;
  border-radius: 3px;
`;

const ChartingGrid = styled.div`
  margin-top: 40px;
  display: grid;
  //width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); //repeat(2, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 80px;
  grid-column-gap: 40px;
  padding: 20px;
`;

const ChartWrapper = styled.div`
  height: 400px;
  padding-bottom: 30px;
  margin-bottom: 10px;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
  height: calc(100% - 20px);

  .BaseTable {
    &__header-row {
      background-color: #1a89c2;
      color: #ffffff;
      font-weight: 500;
    }

    &__row:nth-of-type(odd) {
      background-color: #f6f6f6 !important;
    }

    &__row-cell,
    &__header-cell {
      border: 1px solid #eeeeee;
      justify-content: center;
    }
  }
`;
